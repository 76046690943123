@use '../../styles/shared/include' as *;
.miniCtaBanner {
	background-color: $cream;
	.bannerContainer {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		padding: rem(48) rem(20);
		gap: rem(24);
		justify-content: center;
		p {
			font-size: rem(20);
			line-height: 140%;
			font-size: rem(20);
			font-weight: 500;
			color: $deepBlue;

			i {
				font-weight: 300;
			}
			@media #{$xl} {
				p {
					font-size: rem(32);
				}
			}
		}

		.buttonContainer {
			display: flex;
			flex-direction: column;
			gap: rem(12);
			button {
				margin: 0;
			}
			@media #{$md} {
				width: fit-content;
				flex-direction: row;
			}
		}
		@media #{$md} {
			gap: rem(48);
			p {
				font-size: rem(28);
			}
		}
		@media #{$xxl} {
			padding: rem(48) rem(0);
			p {
				font-size: rem(32);
			}
		}
	}

	.onlyTextContainer {
		color: $deepBlue;

		padding: rem(30);

		p {
			max-width: rem(960);
			font-size: rem(16);
			font-weight: 500;
			line-height: 140%;
		}
		@media #{$md} {
			display: flex;
			justify-content: center;
			padding: rem(48) rem(120);
		}
	}
}
